import React, { useEffect } from 'react'
import './Products.css'
import { products } from '../../media/products/products'
import Product from './Product'
import { connect } from 'react-redux';

const Products = ({ selectedProduct }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedProduct])

  const renderItems = (items) => {
    return items.map((item) => {
      const { src, name, price } = item
      return <Product key={name} image={src} name={name} price={price} />
    })
  }

  const renderSection = () => {
    if (!(products && products.length)) return null;

    const product = products.find((product) => product.section.toLowerCase() === selectedProduct.toLowerCase());
    if (!product) return null;

    const { section, items } = product;
    return (
      <>
        <div  className="products__section">{section}</div>
        { renderItems(items) }
      </>
    )
  }
  
  return (
    <div className="products__container">
      <div className="products__inner-container">
        <div className="products__products">
          {renderSection()}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ selectedProduct }) => ({
  selectedProduct,
});

export default connect(
  mapStateToProps,
)(Products);
