import React, { useEffect } from 'react'
import './Contact.css'
import cookies from '../../media/cookies.jpg'

import facebook from '../../media/facebook.png';
import email from '../../media/email.png';
import call from '../../media/call.png';

import { Grid } from '@material-ui/core';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Grid container spacing={0} justify="center" alignItems="center">
      <Grid item xs={10}>
        <div className="contact__title">Pedidos</div>
      </Grid>
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={10} md={3}>
          <div className="box icon">
            <a target="_blank" href="https://facebook.com/correpintos" rel="noreferrer">
              <img src={facebook} alt="facebook"/>
            </a>
          </div>
          <div className="box text">
            <a target="_blank" href="https://facebook.com/correpintos" rel="noreferrer">
              https://facebook.com/correpintos
            </a>
          </div>
        </Grid>
        <Grid item xs={10} md={3}>
          <div className="box icon">
            <a target="_blank" href="mailto:test@correpintos.com" rel="noreferrer">
              <img src={email} alt="email"/>
            </a>
          </div>
          <div className="box text">
            <a href="mailto:pedidos@correpintos.com">pedidos@correpintos.com</a>
          </div>
        </Grid>
        <Grid item xs={10} md={3}>
          <div className="box icon">
            <a target="_blank" href="tel:12221937049" rel="noreferrer">
              <img src={call} alt="call"/>
            </a>
          </div>
          <div className="box text">
            <a target="_blank" href="tel:12221937049" rel="noreferrer">22-21-93-70-49</a>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={4}>
        <div className="contact__image-container"><img src={cookies} alt="galletas" /></div>
      </Grid>
    </Grid>
  )
}

export default Contact
