import React from 'react'
import './Header.css';
import Logo from '../Logo'
import Menu from '../Menu/Menu';

const Header = () => {
  return (
    <div className="header__container">
      <Logo />
      <Menu />
    </div>
  )
};

export default Header;
