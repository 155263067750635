import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Body from './components/Body'
import Header from './components/Header'
import Footer from './components/Footer'
import Products from "./components/Products";
import About from "./components/About";
import Contact from "./components/Contact";

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route exact path="/">
            <Body />
          </Route>
          <Route path="/productos">
            <Products />
          </Route>
          <Route path="/acercade">
            <About />
          </Route>
          <Route path="/pedidos">
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
