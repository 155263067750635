import React, { useEffect } from 'react'
import './Body.css'

const Body = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="body__container">
      <div className="body__title_container">
        <div className="body__title">Hecho con amor.</div>
        <div className="body__subtitle">... y entregado a domicilio.</div>
        <div className="body__tagline">¿Qu&eacute;  m&aacute;s puedes pedir?</div>
      </div>
    </div>
  )
}

export default Body;

