import React from 'react'
import './Footer.css';
import facebook from '../../media/facebook-white-18dp.svg';
import email from '../../media/email-white-18dp.svg';
import call from '../../media/call-white-18dp.svg';

const Footer = () => {
  return (
    <div className="footer__container">
      <div>
        <a target="_blank" href="https://facebook.com/correpintos" rel="noreferrer">
          <img src={facebook} alt="facebook"/>
        </a>
        <a target="_blank" href="mailto:test@correpintos.com" rel="noreferrer">
          <img src={email} alt="email"/>
        </a>
        <a target="_blank" href="tel:12221937049" rel="noreferrer">
          <img src={call} alt="call"/>
        </a>
      </div>
      <div className="footer__copyright">&#169; 2021 Correpintos Creaciones</div>
    </div>
  )
};

export default Footer;
