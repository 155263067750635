import React from 'react'
import './Product.css'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

const Product = ({ image, name, price }) => {
  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={10} sm={4}>
        <Link to="/pedidos">
          <img className="product__img" src={image} alt={name} />
        </Link>
      </Grid>
      <Grid item xs={10} sm={3} className="product__info-container">
        <Link to="/pedidos">
          <div className="product__info">
            <div className="product__name">{name}</div>
            <div className="product__price">${price}</div>
          </div>
        </Link>
      </Grid>
    </Grid>
  )
}

export default Product;
