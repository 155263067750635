import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
import { slide as BurgerMenu } from "react-burger-menu";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectProduct } from "../../actions/selectProduct";
import { products } from "../../media/products/products";

const Menu = ({ selectProduct }) => {
  const [open, setOpen] = useState(false);
  const handleOnOpen = () => {
    setOpen(!open);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleProductSelection = (product) => () => {
    selectProduct(product);
    setOpen(false);
  };

  const sections = products.map(product => product.section);
  
  const renderMenus = () => (
    sections.map(section => (
      <Link to={`/productos/${section}`} onClick={handleOnOpen}>
        <div className="menu__sub-item" onClick={handleProductSelection(section)}>
          {section}
        </div>
      </Link>
    ))
  );

  return (
    <BurgerMenu
      right
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      isOpen={open}
      className="menu__burger-menu"
    >
      <Link to="/productos" onClick={handleOnOpen}>
        <div className="menu__item">Productos</div>
      </Link>
      { renderMenus() }
      <Link to="/acercade" onClick={handleOnOpen}>
        <div className="menu__item">Acerca de</div>
      </Link>
      <Link to="/pedidos" onClick={handleOnOpen}>
        <div className="menu__item">Pedidos</div>
      </Link>
    </BurgerMenu>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: bindActionCreators(selectProduct, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Menu);
