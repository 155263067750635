import React from 'react'
import './Logo.css'
import logo from '../../media/logo.jpeg';
import { Link } from 'react-router-dom';
import bird from '../../media/bird.png';

const Logo = () => {
  return (
    <>
      <div className="logo__container">
        <Link to="/" className="logo__link">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="logo__text">
        <Link to="/" className="logo__link">
          <img src={bird} alt="pájaro" />
        </Link>
        <Link to="/" className="logo__link">
          <div>Correpintos</div>
        </Link>
      </div>
    </>
  )
}

export default Logo;
