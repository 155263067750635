const reducer = (state = 'galletas', action) => {
  switch (action.type) {
    case "SELECT_PRODUCT": {
      const { product } = action;
      return product;
    }
    default:
      return state;
  }
};

export default reducer;
