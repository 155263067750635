import besosCafe from './galletas/besos-cafe.jpg';
import galletasDibujo from './galletas/galletas-dibujo.jpg';
import galletasDino from './galletas/galletas-dino.jpg';
import galletasSuper from './galletas/galletas-super.jpg';
import galletasMantequilla from './galletas/galletas-mantequilla.jpg';

import gelatinaMazpan from './gelatinas/mazapan.jpg';

import salsaMacha from './salsas/salsa-macha.jpg';

import tartaletaChocolate from './tartaletas/tartaletas-chocolate.jpg';

import panqueNaranja from './panques/panque-naranja.jpg';
import panqueLimon from './panques/panque-limon.jpg';
import panqueNuez from './panques/panque-nuez.jpg';

import nakedCake from './pasteles/naked-cake.jpg';
import tartaletaChocolateBlanco from './pasteles/tartaleta-chocolate-blanco.jpg';

import panCaja from './panes/pan-caja.jpg';
import panecillos from './panes/panecillos.jpg';

export const products = [
  {
    section: "Galletas",
    items: [
      {
        src: besosCafe,
        name: "Besos de café (galletas elaboradas con auténtica mantequilla y rellenas de chocolate semi amargo)",
        price: "100 250gr",
      },
      {
        src: galletasMantequilla,
        name: "Caja de galletas de mantequilla con mermelada y chocolate",
        price: 220,
      }
    ],
  },
  {
    section: "Gelatinas",
    items: [
      {
        src: gelatinaMazpan,
        name: "Gelatina de Mazapán",
        price: 140,
      },
    ],
  },
  {
    section: "Salsas",
    items: [
      {
        src: salsaMacha,
        name: "Salsa macha especial (con cacahuate, semillas, ajonjolí y un toque de pasas y arándanos)",
        price: 60,
      }
    ]
  },
  {
    section: "Tartaletas",
    items: [
      {
        src: tartaletaChocolate,
        name: "Tartaleta de chocolate con fresas",
        price: "60 c/u",
      }
    ]
  },
  {
    section: "Panqués",
    items: [
      {
        src: panqueNaranja,
        name: "Panqué glaseado de naranja",
        price: "110",
      },
      {
        src: panqueLimon,
        name: "Panqué de limón",
        price: "130",
      },
      {
        src: panqueNuez,
        name: "Panqué de manzana, nuez y canela",
        price: "140",
      }
    ]
  },
  {
    section: "Kits",
    items: [
      {
        src: galletasDibujo,
        name: "Kits de galletas para decorar. (contiene 8 galletas de mantequilla, 4 duyas de glaseado de diferentes colores, perlitas comestibles para decorar). Diferentes diseños: Animales, círculos, estrellas, avengers, dinosaurios, diseños navideños, diseños de halloween. Mickey mouse, corazones.",
        price: "190 c/u",
      },
      {
        src: galletasDino,
        name: "Dinosaurios",
        price: "190 c/u",
      },
      {
        src: galletasSuper,
        name: "Superhéroes",
        price: "190 c/u",
      },
    ]
  },
  {
    section: "Pasteles",
    items: [
      {
        src: nakedCake,
        name: "Naked cake. Chocolate relleno de  buttercream (mantequilla y queso crema) con fresas decoradas con chocolate semi amargo",
        price: 450,
      },
      {
        src: tartaletaChocolateBlanco,
        name: "Tartaleta de chocolate blanco con frutos rojos",
        price: 450,
      }
    ]
  },
  {
    section: "Panes",
    items: [
      {
        src: panCaja,
        name: "Pan de caja integral en tres presentaciones: natural $65, orégano $65 y premium (nuez, linaza y ajonjolí)",
        price: 75,
      },
      {
        src: panecillos,
        name: "Panecillos a las finas hierbas",
        price: "$65 (media docena)",
      }
    ]
  }
];
